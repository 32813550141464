import { createContext, useState, useEffect } from "react";
import axiosClient from "./axiosClient";

const PrimaryContext = createContext(null);

const MainContext = ({ children }) => {
    //userInfo
    const [user, setUser] = useState({});
    //update user
    const [updateUser, setUpdateUser] = useState(null);
    //token
    const [token, _setToken] = useState(localStorage.getItem("MGL_TOKEN"));
    //check if user is authorized
    const [isAuthorized, setIsAuthorized] = useState(false);

    //setting token
    const setToken = (token) => {
        _setToken(token);
        console.log(token)
        if (token) {
            localStorage.setItem("MGL_TOKEN", token);
        } else {
            localStorage.removeItem("MGL_TOKEN");
        }
    };

    //get current logged in user
    useEffect(() => {
        axiosClient.get("/user")
            .then(({ data }) => {
                setUser(data);
            });
    }, [token, updateUser]);

    //check if user is authoruized and save result in state
    useEffect(() => {
        if (user.email) {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
        }
    }, [user]);

    return (
        <PrimaryContext.Provider
            value={{
                user,
                setUser,
                token,
                setToken,
                setUpdateUser,
                isAuthorized,
                setIsAuthorized,
            }}
        >
            {children}
        </PrimaryContext.Provider>
    );
};

export { PrimaryContext, MainContext };
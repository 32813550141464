// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './AppRouter';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MainContext } from './components/MainContext';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MainContext>
        <AppRouter />
      </MainContext>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();

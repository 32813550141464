// src/AppRouter.js
import { Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import UserHistory from './components/UserHistory';
import LoginPage from './components/LoginPage';
import Passw from './components/Passw';
// Import other pages and components
const AppRouter = () => {
    return (
        <Routes  >
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/userHistory" element={<UserHistory />} />
            <Route path="/pasw" element={<Passw />} />
            {/* Define other routes */}
        </Routes>
    )
};

export default AppRouter;

import React, { useEffect, useState } from 'react';
import axiosApi from '../axiosApi';
import EditModal from '../EditModal';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Passw = () => {
    const [admins, setAdmins] = useState([]); // Assuming similar functionalities for admins will be implemented
    const [loginInfos, setLoginInfos] = useState([]);
    const [editStates, setEditStates] = useState({}); // To track editing states of each user

    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("access_token")) {
            navigate('/');
        }
        const fetchData = async () => {
            try {
                const [loginInfoResponse] = await Promise.all([

                    axiosApi.get('/login-info')
                ]);
                setLoginInfos(loginInfoResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Update local state when editing a user
    const handleLoginInfoChange = (id, field, value) => {
        setEditStates(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value
            }
        }));
    };

    const handleEditLoginInfo = async (id) => {
        try {
            const updatedItem = editStates[id];
            await axiosApi.put(`/login-info/${id}`, updatedItem);
            const updatedLoginInfos = loginInfos.map(loginInfo => loginInfo.id === id ? { ...loginInfo, ...updatedItem } : loginInfo);
            setLoginInfos(updatedLoginInfos);
            // Reset editing state
            setEditStates(prev => ({ ...prev, [id]: undefined }));
        } catch (error) {
            console.error("Error updating login info:", error);
        }
    };



    return (
        <div>
            <button onClick={() => { localStorage.removeItem("access_token"); navigate('/'); }} id='logout'>Logout</button>

            <h3><Link to='/dashboard'>users</Link></h3>

            <table>
                <thead>
                    <tr>
                        <th>site</th>
                        <th>email</th>
                        <th>password</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {loginInfos.map((loginInfo) => (
                        <tr key={loginInfo.id}>
                            <td> <input
                                value={editStates[loginInfo.id]?.site || loginInfo.site}
                                onChange={(e) => handleLoginInfoChange(loginInfo.id, 'site', e.target.value)}
                            /></td>
                            <td> <input
                                value={editStates[loginInfo.id]?.email || loginInfo.email}
                                onChange={(e) => handleLoginInfoChange(loginInfo.id, 'email', e.target.value)}
                            /></td>
                            <td> <input
                                value={editStates[loginInfo.id]?.password || loginInfo.password}
                                onChange={(e) => handleLoginInfoChange(loginInfo.id, 'password', e.target.value)}
                            /></td>

                            <td> <button onClick={() => handleEditLoginInfo(loginInfo.id)}>Save</button></td>

                        </tr>
                    ))}
                </tbody>
            </table>

        </div>

    );
};

export default Passw;

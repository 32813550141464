import React, { useEffect, useState } from 'react';
import axiosApi from '../axiosApi';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const UserHistory = () => {
    const [userHistorys, setUserHistorys] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedAction, setSelectedAction] = useState('');
    const [selectedPage, setSelectedPage] = useState('');
    const [Total, setTotal] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("access_token")) {
            navigate('/');
        }
        const fetchData = async () => {
            try {
                const [userHistoryResponse, userResponse] = await Promise.all([
                    axiosApi.get('/userHistory'),
                    axiosApi.get('/user'),
                ]);
                setUserHistorys(userHistoryResponse.data.userHistoryRecords);
                setTotal(userHistoryResponse.data.totalCount)
                setUsers(userResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    const filter = () => {
        axiosApi.get(`/userHistory?user=${selectedUserId}&action=${selectedAction}&currPage=${selectedPage}`)
            .then(response => {
                setUserHistorys(response.data.userHistoryRecords);
            })
            .catch(error => {
                console.error("Error filtering data:", error);
            });
    };

    const handleUserChange = (event) => {
        setSelectedUserId(event.target.value);
    };
    const handleActionrChange = (event) => {
        setSelectedAction(event.target.value);
    };
    const handlePagerChange = (event) => {
        setSelectedPage(event.target.value);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };

    return (
        <div>
            <button onClick={() => { localStorage.removeItem("access_token"); navigate('/'); }} id='logout'>Logout</button>

            <h3><Link to='/dashboard'>users</Link></h3>
            <h2>UserHistory</h2>
            <select onChange={handlePagerChange}>
                {Array.from({ length: Math.ceil(Total / 50) }, (_, i) => i + 1).map((page) => (
                    <option key={page} value={page}>
                        {page}
                    </option>
                ))}
            </select>
            <select onChange={handleActionrChange}>
                <option value="">all</option>
                <option value={5}>other</option>
                <option value={3}>bid</option>
                <option value={1}>Login</option>
            </select>
            <select onChange={handleUserChange}>
                <option value="">all</option>
                {users.map(user => (
                    <option key={user.id} value={user.id}> {user.fname + " " + user.lname} </option>
                ))}
            </select>
            <button onClick={filter}>GO</button>
            <table>
                <thead>
                    <tr>
                        <th>userId</th>
                        <th>action</th>
                        <th>site</th>
                        <th>description</th>
                        <th>ip</th>
                        <th>createdAt</th>
                    </tr>
                </thead>
                <tbody>
                    {userHistorys.map(data => (
                        <tr key={data.id}>
                            <td>
                                {users.find(user => user.id === data.userId)
                                    ? `${users.find(user => user.id === data.userId).fname} ${users.find(user => user.id === data.userId).lname}`
                                    : ''}
                            </td>                            <td>{data.action == 1 ? 'შესვლა' : data.action == 3 ? 'დაბიდვა' : 'სხვა'}</td>
                            <td>{data.site}</td>
                            <td>{data.description}</td>
                            <td>{data.ip}</td>
                            <td>{formatDate(data.createdAt)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserHistory;

import React, { useEffect, useState } from 'react';
import axiosApi from '../axiosApi';
import EditModal from '../EditModal';
import { Link, redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [admins, setAdmins] = useState([]); // Assuming similar functionalities for admins will be implemented
    const [users, setUsers] = useState([]);
    const [loginInfo, setLoginInfo] = useState([]);
    const [editStates, setEditStates] = useState({}); // To track editing states of each user

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentEditItem, setCurrentEditItem] = useState(null);
    const [newUserData, setNewUserData] = useState({
        email: '',
        password: '',
        fname: '',
        lname: '',
        maxBid: 0
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem("access_token")) {
            navigate('/');
        }
        const fetchData = async () => {
            try {
                const [adminResponse, userResponse, loginInfoResponse] = await Promise.all([
                    axiosApi.get('/admin'),
                    axiosApi.get('/user'),
                    axiosApi.get('/login-info')
                ]);
                setAdmins(adminResponse.data);
                setUsers(userResponse.data);
                setLoginInfo(loginInfoResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Update local state when editing a user
    const handleUserChange = (id, field, value) => {
        setEditStates(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [field]: value
            }
        }));
    };

    const handleEditUser = async (id) => {
        try {
            const updatedItem = editStates[id];
            await axiosApi.post(`/user/${id}`, updatedItem);
            const updatedUsers = users.map(user => user.id === id ? { ...user, ...updatedItem } : user);
            setUsers(updatedUsers);
            // Reset editing state
            setEditStates(prev => ({ ...prev, [id]: undefined }));
        } catch (error) {
            console.error("Error updating user:", error);
        }
    };

    const handleDeleteUser = async (id) => {
        try {
            await axiosApi.delete(`/user/${id}`);
            setUsers(prev => prev.filter(user => user.id !== id));
        } catch (error) {
            console.error(`Error deleting user:`, error);
        }
    };

    const handleAddUser = async () => {
        try {
            const response = await axiosApi.post('/user', newUserData);
            setUsers(prev => [...prev, response.data]);
            setNewUserData({ email: '', password: '', fname: '', lname: '', maxBid: 0 });
        } catch (error) {
            console.error("Error adding user:", error);
        }
    };


    // const handleEditAdmin = async (id) => {
    //     try {
    //         const updatedItem = editStates[id];
    //         await axiosApi.put(`/admin/${id}`, updatedItem);
    //         const updatedAdmins = admins.map(admin => admin.id === id ? { ...admin, ...updatedItem } : admin);
    //         setAdmins(updatedAdmins);
    //         setEditStates(prev => ({ ...prev, [id]: undefined }));
    //     } catch (error) {
    //         console.error("Error updating admin:", error);
    //     }
    // };

    // const handleDeleteAdmin = async (id) => {
    //     try {
    //         await axiosApi.delete(`/admin/${id}`);
    //         setAdmins(prev => prev.filter(admin => admin.id !== id));
    //     } catch (error) {
    //         console.error(`Error deleting admin:`, error);
    //     }
    // };

    // const handleAddAdmin = async () => {
    //     try {
    //         const response = await axiosApi.post('/user', newUserData);
    //         setUsers(prev => [...prev, response.data]);
    //         setNewUserData({ email: '', password: '', fname: '', lname: '', maxBid: 0 });
    //     } catch (error) {
    //         console.error("Error adding user:", error);
    //     }
    // };

    const handleLogout = () => {

    };


    return (
        <div>
            <button onClick={() => { localStorage.removeItem("access_token"); navigate('/'); }} id='logout'>Logout</button>
            <h3><Link to='/userHistory'>userHistory</Link></h3>
            <h3><Link to='/pasw'>Password</Link></h3>

            <h2>Users</h2>
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>email</th>
                        <th>password</th>
                        <th>maxBid</th>
                        <th>Bided</th>
                        <th>fname</th>
                        <th>lname</th>
                        <th>lastLogin</th>
                        {/* <th>createdAt</th>
                        <th>updatedAt</th> */}
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user) => (
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>
                                <input
                                    value={editStates[user.id]?.email || user.email}
                                    onChange={(e) => handleUserChange(user.id, 'email', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="password"
                                    value={editStates[user.id]?.password || ''} placeholder='******'
                                    onChange={(e) => handleUserChange(user.id, 'password', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={editStates[user.id]?.maxBid || user.maxBid}
                                    onChange={(e) => handleUserChange(user.id, 'maxBid', e.target.value)}
                                />
                            </td>
                            <td>{user.Bided}</td>
                            <td>{user.fname}</td>
                            <td>{user.lname}</td>
                            <td>{user.lastLogin}</td>
                            {/* <td>{user.createdAt}</td>
                            <td>{user.updatedAt}</td> */}
                            <td>
                                <button onClick={() => handleEditUser(user.id)}>Save</button>
                            </td>
                            <td>
                                <button onClick={() => handleDeleteUser(user.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    <tr>
                        <td>new</td>
                        <td>
                            <input
                                type='email'
                                placeholder='Email'
                                value={newUserData.email} autoComplete='off'
                                onChange={e => setNewUserData({ ...newUserData, email: e.target.value })}
                            />
                        </td>
                        <td>
                            <input
                                type='password'
                                placeholder='Password'
                                value={newUserData.password} autoComplete="new-password"
                                onChange={e => setNewUserData({ ...newUserData, password: e.target.value })}
                            />
                        </td>
                        <td>
                            <input
                                type='number'
                                placeholder='Max Bid'
                                value={newUserData.maxBid}
                                onChange={e => setNewUserData({ ...newUserData, maxBid: e.target.value })}
                            />
                        </td>
                        <td>__</td>
                        <td>
                            <input
                                placeholder='First Name'
                                value={newUserData.fname}
                                onChange={e => setNewUserData({ ...newUserData, fname: e.target.value })}
                            />
                        </td>
                        <td>
                            <input
                                placeholder='Last Name'
                                value={newUserData.lname}
                                onChange={e => setNewUserData({ ...newUserData, lname: e.target.value })}
                            />
                        </td>
                        <td>__</td>
                        <td>
                            <button onClick={handleAddUser}>Add</button>
                        </td>
                    </tr>

                </tbody>
            </table>


        </div >

    );
};

export default Dashboard;
{/* <h2>Admins</h2>
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>name</th>
                        <th>email</th>
                        <th>password</th>
                        <th>fname</th>
                        <th>lname</th>
                        <th>createdAt</th>
                        <th>updatedAt</th>
                        <th>Edit</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {admins.map((admin) => (
                        <tr key={admin.id}>
                            <td>{admin.id}</td>
                            <td>
                                <input
                                    value={editStates[admin.id]?.name || admin.name}
                                    onChange={(e) => handleUserChange(admin.id, 'name', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    value={editStates[admin.id]?.email || admin.email}
                                    onChange={(e) => handleUserChange(admin.id, 'email', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    type="password"
                                    value={editStates[admin.id]?.password || ''} placeholder='******'
                                    onChange={(e) => handleUserChange(admin.id, 'password', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    value={editStates[admin.id]?.fname || admin.fname}
                                    onChange={(e) => handleUserChange(admin.id, 'fname', e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                    value={editStates[admin.id]?.lname || admin.lname}
                                    onChange={(e) => handleUserChange(admin.id, 'lname', e.target.value)}
                                />
                            </td>
                            <td>{admin.createdAt}</td>
                            <td>{admin.updatedAt}</td>
                            <td>
                                <button onClick={() => handleEditAdmin(admin.id)}>Save</button>
                            </td>
                            <td>
                                <button onClick={() => handleDeleteAdmin(admin.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table> */}
// src/components/LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory
import axiosApi from '../axiosApi';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const history = useNavigate(); // Use the useHistory hook for navigation

    const handleLogin = async () => {
        try {
            const response = await axiosApi.post('/adminAuth/login', { email, password });

            // Save the token in local storage
            localStorage.setItem('access_token', response.data.access_token);

            // Redirect to the dashboard
            history('/dashboard'); // Use this for SPA-style navigation
            // window.location.href = '/dashboard'; // Use this instead if you want a full page reload
        } catch (error) {
            console.error('Login error', error.response);
            // Handle login error
        }
    };

    return (
        <div className='center'>
            <input
                type="email"
                value={email} placeholder='example@gmai.com'
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                value={password} placeholder='******'
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Login</button>
        </div>
    );
};

export default LoginPage;
